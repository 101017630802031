import { NavLink } from "react-router-dom";
import { HeadText } from "../../components/HeadText";
import "./CookiesPage.scss";

export const CookiesPage = ({ cookieText }) => {
  return (
    <div className="cookies-box container">
      <NavLink to="/" className="link-to-home">
        &#60;Home
      </NavLink>
      <div className="cookies-box__all-cookies-info">
        <h1 className="cookies-box__header-cookies-policy">
          <HeadText text="Our Cookie Policy" />
        </h1>
        <p className="cookies-box__cookies-text-policy inter">{cookieText}</p>
      </div>
    </div>
  );
};
