import "./MobCareersBox.scss";
import { HeadText } from "../../components/HeadText";
import { VacanciesBox } from "../../components/VacanciesBox";
import { useEffect, useState } from "react";
import leftArrow from "../../images/left-arrow.svg";
import rightArrow from "../../images/right-arrow.svg";
import { ref } from "firebase/database";
import { Spinner } from "../../components/Spinner";
import { database } from "../../firebase";
import { getFireDataBase } from "../../components/utils/getFireDataBase";
import { useSwipeable } from "react-swipeable";

export const MobCareersBox = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const dataRef = ref(database, "vacancies");
    setLoading(true);
    getFireDataBase(setData, setLoading, dataRef);
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const dataLength = Object.keys(data).length;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === dataLength - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? dataLength - 1 : prevIndex - 1
    );
  };

  return (
    <div className="mob-careers-box container" {...handlers}>
      <div className="mob-careers-box__vacancies-head-text head-text-box">
        <HeadText text="Vacancies" />
      </div>
      <div className="mob-careers-box__careers-space container">
        <div
          className="mob-careers-box__all-cards"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {Object.keys(data).map((key) => {
            const job = data[key];
            return (
              <div className="mob-careers-box__slider" key={key}>
                <VacanciesBox
                  key={key}
                  vacanciesName={key}
                  jobTitle={job.jobTitle}
                  workDay={job.workDay}
                  workPlace={job.workPlace}
                  jobPosition={job.jobPosition}
                  workRegion={job.workRegion}
                  status={job.status}
                  description={job.description}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mob-careers-box__botton-position">
        <div className="mob-careers-box__carousel-controls">
          <button className="mob-careers-box__btn1" onClick={handlePrev}>
            &lt;
          </button>
          <button className="mob-careers-box__btn2" onClick={handleNext}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};
