import "./PrivacyPage.scss";
import { HeadText } from "../../components/HeadText";
import { NavLink } from "react-router-dom";

export const PrivacyPage = ({ privacyText }) => {
  return (
    <div className="privacy-box container">
      <NavLink to="/" className="link-to-home">
        &#60;Home
      </NavLink>
      <div className="privacy-box__all-privacy-info">
        <h1 className="privacy-box__header-privacy-policy">
          <HeadText text="Our Privacy Policy" />
        </h1>
        <p className="privacy-box__privacy-text-policy inter">{privacyText}</p>
      </div>
    </div>
  );
};
