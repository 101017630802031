import "./TermsOfUsePage.scss";
import { HeadText } from "../../components/HeadText";
import { NavLink } from "react-router-dom";

export const TermsOfUsePage = ({ termsOfUse }) => {
  return (
    <div className="terms-of-use-box container">
      <NavLink to="/" className="link-to-home">
        &#60;Home
      </NavLink>

      <div className="terms-of-use-box__all-terms-info">
        <h1 className="terms-of-use-box__header-terms-policy">
          <HeadText text="Our Terms of Use" />
        </h1>
        <p className="terms-of-use-box__terms-text-policy inter">
          {termsOfUse}
        </p>
      </div>
    </div>
  );
};
